import React, { Component } from 'react'

class AdsComponent extends Component {

    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({})
    }

    render() {
        return (
            <div>
                <ins className="adsbygoogle"
                    style={{ display: "inline-block", width: "728px", height: "90px" }}
                    data-ad-client="ca-pub-1088527069494370"></ins>
            </div>)
    }
}

export default AdsComponent;