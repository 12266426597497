import React, { Component } from "react";
import "./styles.scss";

export default class Footer extends Component {
   render() {
      return (
         <div className="footer">
            Developed By <a href="https://github.com/HeinKoZin">Hein Ko Zin</a>
         </div>
      );
   }
}
